import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

interface CheckBox extends FC<IconsProps> {
  Small: FC<IconsProps>;
}

const CheckBoxChecked: CheckBox = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="4" y="4" width="16" height="16" rx="4" fill="#4728CD" />
    <path
      d="M15 10L11 14L9 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

const Small: CheckBox['Small'] = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <rect width="16" height="16" rx="4" fill="#4728CD" />
      <path
        d="M11 6L7 10L5 8"
        stroke="white"
        stroke-width="2"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  );
};

CheckBoxChecked.Small = Small;

export default CheckBoxChecked;
